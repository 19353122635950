<template>
  <b-rate
    v-if="showScore && score"
    :value="rate"
    :disabled="disabled"
    :custom-text="score / 2 + ' / 5'"
    :show-text="false"
    :show-score="false"
  />
  <b-rate
    v-else
    :value="rate"
    :disabled="disabled"
    :show-text="false"
    :show-score="false"
  />
</template>

<script>
export default {
  props: {
    showScore: {
      type: Boolean,
      default: false,
    },
    score: {
      type: Number,
      default: null,
    },
    rate: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.rate {
  margin-bottom: 0 !important;

  & .rate-text {
    margin-top: 4px;
  }
}
</style>
