import { useContext } from "@nuxtjs/composition-api";

export function useTaskPriceFormatters() {
  const { i18n } = useContext();

  function formatTaskPrice(price, includeUnit = true) {
    const isFromTo = !!(
      price.priceFrom &&
      price.priceTo &&
      price.priceFrom !== price.priceTo
    );

    const value = isFromTo
      ? `${i18n.n(price.priceFrom, price.priceType)} - ${i18n.n(
          price.priceTo,
          price.priceType
        )}`
      : i18n.n(price.price, price.priceType);

    if (includeUnit) {
      return `${value} / ${price?.priceTag?.unit ?? price.professionTask.unit}`;
    }

    return value;
  }

  return {
    formatTaskPrice,
  };
}
