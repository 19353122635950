<template>
  <JoszakiCheckbox v-model="terms" :validation-state="newTender.v$.value.terms">
    <template #label>
      {{ $t("newTender.readAndAgree") }}
      <NuxtLink :to="localePath({ name: 'terms' })" target="_blank">{{
        $t("newTender.conditions")
      }}</NuxtLink>
    </template>
  </JoszakiCheckbox>
</template>

<script>
import { useNewTender } from "~/composables/tender/useNewTender";

export default {
  setup() {
    const newTender = useNewTender();
    return {
      newTender,
      terms: newTender.terms,
    };
  },
};
</script>
