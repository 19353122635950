<template>
  <div class="BoxTender bg-white rounded-lg shadow-md p-4">
    <div class="flex justify-between">
      <h2 class="text-primary-h2 font-bold text-3xl mb-2">Küldj üzenetet</h2>
      <IconComponent
        v-if="showClose"
        class="!text-xl cursor-pointer"
        icon="times"
        @click.native.stop="$emit('close')"
      />
    </div>
    <div class="text-primary mb-1">
      Ingyenes üzenetküldés a szakembernek. Kérdezz bármit!
    </div>
    <FieldDescription @focus="descriptionFocused" @blur="descriptionBlurred" />
    <FieldEmail v-if="submitStarted" :show-education="false" />
    <FieldPhone v-if="submitStarted" :show-education="false" />
    <FieldCity
      v-if="submitStarted && !newTender.state.prefilled"
      :show-education="false"
    />
    <FieldProfession
      v-if="submitStarted && !newTender.state.prefilled"
      :show-education="false"
    />
    <FieldConsent v-if="submitStarted" />
    <JoszakiButton
      class="mt-4"
      :loading="loading"
      @click="startSubmitJob(!submitStarted)"
    >
      Küldés
    </JoszakiButton>
  </div>
</template>

<script>
import FieldEmail from "~/components/_refactored/newTender/FieldEmail.vue";
import FieldPhone from "~/components/_refactored/newTender/FieldPhone.vue";
import FieldDescription from "~/components/_refactored/newTender/FieldDescription.vue";
import FieldCity from "~/components/_refactored/newTender/FieldCity.vue";
import FieldProfession from "~/components/_refactored/newTender/FieldProfession.vue";
import FieldConsent from "~/components/_refactored/newTender/FieldConsent.vue";
import { useNewTender } from "~/composables/tender/useNewTender";

export default {
  components: {
    FieldDescription,
    FieldPhone,
    FieldEmail,
    FieldCity,
    FieldProfession,
    FieldConsent,
  },
  props: {
    person: {
      type: Object,
      default: null,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const newTender = useNewTender();

    return {
      newTender,
    };
  },
  data() {
    return {
      submitStarted: false,
      focusSent: false,
      blurSent: false,
      loading: false,
    };
  },
  methods: {
    descriptionFocused() {
      if (this.focusSent) {
        return;
      }
      this.focusSent = true;
      this.$trackers.trackEvent({
        title: "Tender Description Focused",
        data: {
          source: "boxtender",
        },
      });
    },
    descriptionBlurred() {
      if (this.blurSent) {
        return;
      }
      this.blurSent = true;
      this.$trackers.trackEvent({
        title: "Tender Description Blurred",
        data: {
          description: this.newTender.jobDescription.value,
          source: "boxtender",
        },
      });
    },
    async startSubmitJob(initial) {
      this.loading = true;
      if (initial) {
        this.submitStarted = true;
        this.$trackers.trackRequestTender({
          personId: this.person?.id ?? null,
          personIsActive: this.person?.isActive ?? null,
          citySeoName: this.newTender?.city?.value?.seoName,
          countySeoName: this.newTender?.city?.value?.county?.seoName,
          professionSeoName: this.newTender?.value?.profession?.seoName,
          professionGroupSeoName:
            this.newTender?.profession?.value?.professionGroup?.seoName,
          source: "profile-box-tender",
        });
        this.loading = false;
        return;
      }
      const valid = await this.newTender.v$.$validate();
      if (!valid) {
        this.loading = false;
        return;
      }

      try {
        this.$track.analyticsEvent({
          event: "submit_job",
          category: "ajanlatkeres",
          action: "Submit job",
          label: "v2.0",
        });

        this.$trackers.trackEvent({
          title: "Submit Job",
          data: {
            professionSeoName: this.newTender.profession.value.seoName,
            professionGroupSeoName:
              this.newTender.profession?.value.professionGroup?.seoName,
            citySeoName: this.newTender.city.value.seoName,
            v: 2,
          },
        });
        const tender = await this.newTender.createTender(true, false);

        this.$track.analyticsEvent({
          category: "ajanlatkeres",
          action: "normal",
        });
        this.loading = false;

        if (this.person.isInactive) {
          // unreachable code at the moment
          this.$router.push(
            this.localePath({
              name: "requestForTenderTab",
              params: {
                activeTab: 8,
              },
              query: Object.assign({}, this.$route.query, {
                munka: tender.id,
                personId: this.person.id,
              }),
            })
          );
        } else {
          this.$router.push(
            this.localePath({
              name: "specifyTenderPage",
              params: {
                tenderId: tender.id,
                activeTab: 1,
              },
            })
          );
        }
      } catch (error) {
        console.error(error);
        this.$sentry.captureException(error, {
          tags: {
            tender: "boxtender",
          },
        });
        this.$buefy.toast.open({
          message:
            typeof error === "string"
              ? error
              : "Hiba történt az üzenet küldése közben",
          type: "is-danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  //
};
</script>
