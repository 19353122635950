<template>
  <JoszakiPill :type="type">
    {{ description }}
  </JoszakiPill>
</template>

<script>
import { computed } from "vue";
import JoszakiPill from "~/components/_refactored/common/JoszakiPill.vue";

export default {
  components: {
    JoszakiPill,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const type = computed(() => {
      switch (props.name) {
        case "certified_professional":
          return "rating";
        case "advisor":
          return "success";
        case "professional":
          return "primary";
        case "suspicious":
          return "error";
        default:
          return "default";
      }
    });

    return {
      type,
    };
  },
};
</script>
