<template>
  <div class="person-card-container relative">
    <div
      v-if="showActiveIndicator && person.isActive"
      class="text-white font-bold rounded-md w-fit h-12 -mb-3 py-1 pl-1 pr-2"
      :class="{
        'bg-success': person.isActive,
        '!bg-gray-400': !person.isActive,
      }"
    >
      <b-icon
        icon="circle"
        class="text-xs -mr-1"
        :class="{
          blink: person.isActive,
          'text-gray-300': !person.isActive,
        }"
      />
      <span class="uppercase">
        {{
          person.isActive
            ? $t("personProfile.openToOffers")
            : $t("personProfile.notOpenToOffers")
        }}
      </span>
    </div>
    <div
      ref="card"
      class="card person-card"
      :class="{
        withPrice: professionTaskName,
      }"
      @click="goToPersonProfile"
    >
      <div class="card-body">
        <div class="top-content">
          <div class="picture">
            <PersonPictureSet :person-id="person.id" :alt="person.name" />
            <div v-if="isVisited" class="is-visited-badge">
              <b-icon icon="eye" />
              {{ $t("personCard.visited") }}
            </div>
          </div>
          <div class="main">
            <div>
              <div class="name">
                <NuxtLink
                  class="nameLink"
                  :to="
                    localePath({
                      name: 'professionalPage',
                      params: {
                        professionalSeoName: person.seoName,
                      },
                    })
                  "
                  target="_blank"
                  :rel="`${person.excluded ? 'nofollow' : ''}`"
                  @click.native.prevent="
                    $trackers.trackClick('PersonCardName', trackingData)
                  "
                >
                  {{ person.name }}
                </NuxtLink>
              </div>
              <div class="professions">
                <b-icon v-if="professions?.length" icon="briefcase" />
                <p
                  v-for="prof in professions"
                  :key="`profession-${prof.id}`"
                  class="profession"
                >
                  {{ prof.name }}
                </p>
              </div>
            </div>
            <div v-if="person.memo" class="description">
              <div class="memoText">
                {{ person.memo }}
              </div>
              <NuxtLink
                class="description-link"
                :to="
                  localePath({
                    name: 'professionalPage',
                    params: {
                      professionalSeoName: person.seoName,
                    },
                  })
                "
                target="_blank"
                :rel="`${person.excluded ? 'nofollow' : ''}`"
                @click.prevent
              >
                {{ $t("personCard.more") }}
              </NuxtLink>
            </div>
            <div v-if="!cityName" class="location">
              <b-icon icon="map-marker-alt" size="is-small" />
              <span v-if="showGoToHouseKm && person.goToHouse">
                <template v-if="goToWholeCountry">
                  {{ $t("personProfile.location.everywhere") }}
                </template>
                <template v-else>
                  <i18n path="personProfile.location.goToHouse">
                    <template #city>
                      <span class="font-bold">
                        {{ personCity }}
                      </span>
                    </template>
                    <template #km>
                      <span class="font-bold">
                        {{ person.goToHouseKm }}
                      </span>
                    </template>
                  </i18n>
                </template>
              </span>
              <span v-else>{{ personCity }}</span>
            </div>
            <div v-else-if="cityName" class="location stat">
              <b-icon icon="check" class="text-[#1fbb00]" />
              <span>{{ $t("workThere.text", { cityName }) }}</span>
            </div>
            <div v-else>
              <!-- this is unreachable -->
              <b-icon icon="map-marker-alt" />
              <template v-if="person.goToHouse">
                <template v-if="goToWholeCountry">
                  {{ $t("personProfile.location.everywhere") }}
                </template>
                <template v-else>
                  <i18n path="personProfile.location.goToHouse">
                    <template #city>
                      <b>{{ person.city.name }}</b>
                    </template>
                    <template #km>
                      <b>
                        {{ person.goToHouseKm }}
                      </b>
                    </template>
                  </i18n>
                </template>
              </template>
              <template v-else>
                <i18n path="personProfile.location.inCity">
                  <template #city>
                    <b>{{ person.city.name }}</b>
                  </template>
                </i18n>
              </template>
            </div>
          </div>
          <div class="right">
            <div class="rating">
              <div class="flex justify-end">
                <div
                  v-if="!isFavoritesDisabled"
                  class="mr-1 !text-xl"
                  @click.prevent.stop="toggleLike"
                >
                  <IconComponent
                    v-if="isLiked"
                    class="text-red-500"
                    icon="heart"
                  />
                  <IconComponent v-else pack="far" icon="heart" />
                </div>

                <RatingIndicator
                  :rate="person.score / 2"
                  :disabled="true"
                  class="rating-stars"
                />
              </div>

              <div
                v-if="personLabels && personLabels.length"
                class="rating-badges"
              >
                <PersonBadge
                  v-for="badge in personLabels"
                  :key="`v2${badge.name}`"
                  :name="badge.name"
                  :description="badge.description"
                  class="badge-tag mb-1"
                />
              </div>
            </div>

            <!-- Show price when available -->
            <div v-if="displayPrice" class="profession-task-price">
              <p class="font-semibold text-xs first-letter:capitalize">
                {{ professionTaskName }}
              </p>
              <p class="price">
                {{ formatTaskPrice(displayPrice) }}
              </p>
            </div>

            <div class="stats">
              <div v-if="showKarma" class="stat !font-bold">
                <template v-if="highlightedProfession?.karma">
                  {{
                    $t("personCard.karma", {
                      count: highlightedProfession.karma,
                    })
                  }}
                </template>
                <template v-else>
                  {{ $t("personCard.noKarma") }}
                </template>
              </div>
              <div v-if="person.winCount > 0" class="stat">
                {{ $t("personCard.comission", { count: person.winCount }) }}
                <b-icon icon="check" class="green" />
              </div>
              <div v-if="person.scoreCount > 0" class="stat">
                {{ $t("personCard.opinion", { count: person.scoreCount }) }}
                <b-icon icon="star" class="yellow" />
              </div>
              <div v-if="person.answerCount > 0" class="stat">
                {{ $t("personCard.answers", { count: person.answerCount }) }}
                <b-icon icon="comments" class="pink" />
              </div>
              <div v-if="photoCount > 0" class="stat">
                {{ $t("personCard.referencePhotos", { count: photoCount }) }}
                <b-icon icon="camera" />
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-content">
          <slot name="buttons">
            <JoszakiButton
              v-if="person.isActive && person.phoneNumber?.number"
              class="card-button h-9"
              type="info"
              icon-left="phone-alt"
              :inverted="true"
              :outline="true"
              @click.prevent.stop="phoneNumberClicked(person, $event)"
            >
              {{ $helpers.formatPhoneNumber(person.phoneNumber) }}
            </JoszakiButton>
            <JoszakiButton
              class="card-button h-9"
              type="info"
              icon-left="pencil-alt"
              :inverted="$mq !== 'mobile'"
              :outline="true"
              @click.prevent.stop="buttonClicked(person, $event)"
            >
              {{
                $ab.isVariant("cards-request-now-text", "test")
                  ? "Ajánlatkérés most"
                  : $t(`requestTender.fromPerson`)
              }}
            </JoszakiButton>
          </slot>
        </div>
      </div>
    </div>
    <div
      v-if="showBoxTender"
      class="min-h-full absolute bg-whitesmoke rounded-sm z-50 left-0 right-0 top-[calc(100%+34px)]"
      @click.stop
    >
      <BoxTender
        class="h-full"
        :show-close="true"
        :person="person"
        @close="showBoxTender = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import PersonBadge from "../_refactored/profile/PersonBadge.vue";
import RatingIndicator from "./RatingIndicator.vue";
import PersonPictureSet from "~/components/PersonPictureSet.vue";
import JoszakiButton from "~/components/_refactored/common/JoszakiButton.vue";
import BoxTender from "~/components/_refactored/app/boxes/BoxTender.vue";
import { useTaskPriceFormatters } from "~/composables/taskPriceFormatters";
import IS_PHONE_MISMATCH from "~/components/_refactored/card/isPhoneMismatch.graphql";
import { useSettingsStore } from "~/stores/settings";

export default {
  components: {
    PersonPictureSet,
    RatingIndicator,
    PersonBadge,
    JoszakiButton,
    BoxTender,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
    cityName: {
      type: String,
      default: "",
    },
    showGoToHouseKm: {
      type: Boolean,
      default: false,
    },
    displayPrice: {
      type: Object,
      default: undefined,
    },
    professionTaskName: {
      type: String,
      default: "",
    },
    showKarma: {
      type: Boolean,
      default: false,
    },
    showActiveIndicator: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { formatTaskPrice } = useTaskPriceFormatters();

    return {
      formatTaskPrice,
    };
  },
  data() {
    return {
      isLiked: false,
      isVisited: false,
      showBoxTender: false,
    };
  },
  computed: {
    ...mapState(useSettingsStore, {
      isFavoritesDisabled: "isFavoritesDisabled",
    }),
    pageProfession() {
      return this.$store.getters["listPage/profession"];
    },
    pageProfessionTask() {
      return this.$store.getters["listPage/professionTask"];
    },
    pageProfessionTaskProfessionIds() {
      return (this.pageProfessionTask?.professions ?? []).map((p) => p.id);
    },
    photoCount() {
      return this.person?.photoCount || this.person?.photos?.length || 0;
    },
    city() {
      return this.$store.getters["listPage/city"];
    },
    personCity() {
      return this.person.city?.name || this.person.city;
    },
    goToWholeCountry() {
      return this.person?.goToHouseKm > 500;
    },
    highlightedProfession() {
      return (this.person?.professions ?? []).find(
        (p) =>
          p.id === this.pageProfession?.id ||
          this.pageProfessionTaskProfessionIds.includes(p.id)
      );
    },
    personLabels() {
      return [...(this.person?.labels ?? [])].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    professions() {
      function sortProfession(a, b) {
        if (a.groupScore !== b.groupScore) {
          return b.groupScore - a.groupScore;
        }
        if (a.avgScore > b.avgScore) {
          return -1;
        }
        if (b.avgScore > a.avgScore) {
          return 1;
        }
        return 0;
      }
      if (!this.person.professions) {
        return [];
      }
      const professions = [...this.person.professions];
      professions.sort(sortProfession);
      const result = [];
      if (this.highlightedProfession) {
        result.push(this.highlightedProfession);
      }
      for (const profession of professions) {
        if (profession.id === this.highlightedProfession?.id) {
          continue;
        }
        result.push(profession);
        if (result.length === 3) {
          break;
        }
      }
      return result;
    },
    trackingData() {
      return {
        personId: this.person.id,
        isActive: this.person.isActive,
        score: this.person.score,
        scoreCount: this.person.scoreCount,
        winCount: this.person.winCount,
      };
    },
  },
  mounted() {
    this.isLiked = this.$store.getters["localStorage/isLikedPerson"](
      this.person.id
    );
    this.isVisited = this.$store.getters["localStorage/isVisitedPerson"](
      this.person.id
    );
  },
  methods: {
    phoneNumberClicked(person) {
      this.$query(IS_PHONE_MISMATCH, {
        personId: person.id,
        phone: person.phoneNumber.number,
      })
        .then((res) => {
          if (res.isPhoneMismatch) {
            this.$sentry.captureMessage(
              `Phone mismatch for person ${person.id}, phone: ${person.phoneNumber.number} url: ${window.location.href}`,
              {
                level: "fatal",
              }
            );
          }
        })
        .catch((err) => {
          console.error(err, "Failed to query phone mismatch");
        });
      this.$trackers.trackEvent({
        title: "Clicked PersonCard Phone Number",
        data: this.trackingData,
      });
      this.$trackers.trackClick("PersonCardPhoneNumber", this.trackingData);
      this.$emit("callProfessional", person);
    },
    buttonClicked(_person) {
      this.$trackers.trackEvent({
        title: "Clicked PersonCard Button",
        data: this.trackingData,
      });
      this.$trackers.trackClick("PersonCardButton", this.trackingData);

      this.goToTenderRequest();
    },
    goToPersonProfile() {
      this.$track.analyticsEvent({
        category: "szaki-kartya-v2",
        action: "kattintas-szakira",
      });
      this.$trackers.trackClick("PersonCard", this.trackingData);
      this.registerVisited();
      this.$emit("select");
    },
    goToTenderRequest() {
      this.$track.analyticsEvent({
        category: "szaki-kartya-v2",
        action: "kattintas-ajanlatkeresre",
      });

      this.$emit("requestTender", { profession: this.professions?.[0] });
    },

    // Cookie list mutations
    toggleLike() {
      this.isLiked = !this.isLiked;

      this.$trackers.trackClick("PersonCardFavourite", {
        personId: this.person.id,
        liked: this.isLiked,
      });

      this.$trackers.trackEvent({
        title: "Person Favorited",
        data: {
          personId: this.person.id,
          liked: this.isLiked,
        },
      });
      if (this.isLiked) {
        this.$store.dispatch("localStorage/favoritePerson", this.person.id);
      } else {
        this.$store.dispatch("localStorage/unfavoritePerson", this.person.id);
      }
      this.$emit("like", {
        personId: this.person.id,
        liked: this.isLiked,
      });
    },
    registerVisited() {
      this.isVisited = true;
      this.$store.dispatch("localStorage/visitPerson", this.person.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "styles/_all-members" as global;

.profession-task-price {
  text-align: right;
  color: global.$priceColor;

  .profession-task-name {
    font-size: 0.625rem;
    font-weight: 600;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .price {
    font-size: 0.75rem;
    line-height: 120%;
    font-weight: 800;
  }

  .unit {
    font-size: 0.75rem;
    opacity: 0.7;
    font-weight: 600;
  }
}

.person-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: end;

  &.person-swiper-card {
    height: 100% !important;
    .person-card {
      min-width: 540px !important;
    }
  }

  .person-card {
    width: 100%;
    height: 100%;

    .card-body {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: 0.5rem;
      gap: 0.5rem;
      justify-content: space-between;
      height: 100%;

      .top-content {
        display: flex;
        gap: 0.5rem;
        flex: 1;

        .picture {
          flex: 0 0 auto;
          width: 150px;
          min-height: 100%;
          overflow: hidden;
          position: relative;
          isolation: isolate;
          border-radius: 5px;

          @include global.mobile {
            width: 80px;
          }
        }

        .picture::v-deep picture {
          width: 100%;
          height: 100%;
        }

        .picture::v-deep img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .main {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: space-between;
          flex: 1 3 auto;
          min-width: 100px;
          gap: 0.5rem;

          .name {
            display: flex;
            flex-direction: row;
            font-weight: bold;
            gap: 0.5rem;

            @include global.mobile {
              flex-direction: column;
              gap: 0;
            }

            .nameLink {
              color: global.$primary;
            }

            .inactive-badge {
              color: grey;
              opacity: 0.5;

              @include global.mobile {
                font-size: 0.75rem;
              }
            }
          }

          .professions {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .icon {
              font-size: 0.75rem;
              height: unset;
              width: unset;
              margin-right: 0.2rem;
            }

            .profession {
              font-weight: bold;
              font-size: 0.75rem;
              margin-right: 0.2rem;

              &::first-letter {
                text-transform: capitalize;
              }

              @include global.mobile {
                font-size: 0.625rem;
              }

              &:not(:last-of-type):after {
                content: "·";
              }
            }
          }

          .description {
            grid-area: description;
            font-size: 0.75rem;
            background: rgba(black, 0.03);
            padding: 0.5rem;
            border-radius: 5px;

            position: relative;

            display: flex;
            flex: 1;
            flex-direction: column;

            .memoText {
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-word;
              @include global.line-clamp(4);

              @include global.desktop-only {
                font-size: 0.75rem;
                @include global.line-clamp(3);
              }
            }

            .description-link {
              background: global.$primary;
              border-radius: 5px;
              padding: 0.25rem;
              font-size: 0.75rem;
              font-weight: bold;
              display: inline-block;
              color: white;
              width: max-content;
              margin-left: auto;
              margin-top: auto;

              @include global.mobile {
                font-size: 0.625rem;
              }
            }
          }

          .location {
            font-size: 0.75rem;

            .icon {
              width: unset;
              height: unset;
              margin-right: 5px;
            }

            @include global.mobile {
              font-size: 0.625rem;
            }
          }
        }

        .right {
          min-width: 100px;
          display: flex;
          flex-direction: column;
          align-content: flex-end;
          justify-content: space-between;

          @include global.mobile {
            min-width: 90px;
          }

          .rating {
            grid-area: rating;
            justify-self: flex-end;
            text-align: right;

            display: grid;
            gap: 5px;
            font-size: 0.75rem;

            .rating-stars {
              justify-self: flex-end;
              align-self: start;
            }

            .rating-badges {
              display: flex;
              flex-flow: column;
              justify-self: flex-end;
              align-self: start;
              align-items: flex-end;

              .badge-tag {
                font-size: 0.625rem;
                width: max-content;
                font-weight: bold;
              }
            }
          }

          .stats {
            grid-area: stats;
            font-size: 0.75rem;

            display: grid;
            gap: 1px;

            @include global.mobile {
              font-size: 0.625rem;
            }

            .stat {
              justify-self: flex-end;
              align-self: start;
              text-align: right;
              font-weight: 600;

              .icon {
                width: unset;
                height: unset;
                margin-left: 5px;
                color: global.$primary;

                &.green {
                  color: #1fbb00;
                }

                &.yellow {
                  color: #fc9d20;
                }

                &.pink {
                  color: #ec5fe7;
                }
              }
            }
          }
        }
      }

      .bottom-content {
        display: flex;

        @include global.mobile {
          flex-direction: column;
        }

        .card-button {
          flex: 1 1 auto;
          font-size: 0.875rem;

          &:not(:first-of-type) {
            margin-left: 20px;
            @include global.mobile {
              margin: 10px 0 0 0;
            }
          }
        }
      }
    }
  }
}
</style>
